var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"add-task-modal","size":"lg","ok-variant":"success","centered":"","no-close-on-backdrop":"","cancel-variant":"danger","title":_vm.$t('task_manager.add_task'),"ok-title":_vm.$t('save'),"cancel-title":_vm.$t('client.cancel')},on:{"show":_vm.onOpen,"ok":_vm.onSubmit,"cancel":function($event){return _vm.$bvModal.hide('add-task-modal')}}},[_c('validation-observer',{ref:"accountValidation"},[_c('BRow',[_c('BCol',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Контрагенты:"}},[_c('validation-provider',{attrs:{"name":"Контрагенты","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('v-select',{staticClass:"flex-grow-1 mr-1",attrs:{"label":"title","options":_vm.GET_CONTRACTORS_LIST,"reduce":function (p) { return p.id; }},on:{"input":_vm.fetchSettlementAccount},model:{value:(_vm.account.contractor),callback:function ($$v) {_vm.$set(_vm.account, "contractor", $$v)},expression:"account.contractor"}}),_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.$bvModal.show('add-counter-parties-modal')}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('BCol',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"ИНН :"}},[_c('validation-provider',{attrs:{"name":"инн","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"inn","options":_vm.GET_CONTRACTORS_LIST,"reduce":function (p) { return p.id; }},model:{value:(_vm.account.contractor),callback:function ($$v) {_vm.$set(_vm.account, "contractor", $$v)},expression:"account.contractor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('BCol',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Расчетный счет контрагента:"}},[_c('v-select',{attrs:{"label":"title","id":"document_type","options":_vm.GET_SETTLEMENT_ACCOUNT_BY_CONTRACTOR,"reduce":function (p) { return p.id; },"disabled":!_vm.account.contractor},model:{value:(_vm.account.settlement_account),callback:function ($$v) {_vm.$set(_vm.account, "settlement_account", $$v)},expression:"account.settlement_account"}})],1)],1),_c('BCol',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Тип счета:"}},[_c('validation-provider',{attrs:{"name":_vm.$t('details.select_document_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"title","id":"document_type","placeholder":_vm.$t('details.select_document_type'),"options":_vm.GET_BILL_TYPE_LIST,"reduce":function (p) { return p.id; }},model:{value:(_vm.account.bill_type),callback:function ($$v) {_vm.$set(_vm.account, "bill_type", $$v)},expression:"account.bill_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('BCol',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Плательщик:"}},[_c('validation-provider',{attrs:{"name":"Плательщик","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"title","options":_vm.GET_PAYERS_LIST,"reduce":function (p) { return p.id; }},model:{value:(_vm.account.payer),callback:function ($$v) {_vm.$set(_vm.account, "payer", $$v)},expression:"account.payer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('BCol',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Город :"}},[_c('validation-provider',{attrs:{"name":"Город ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"title","options":_vm.GET_CITIES_LIST,"reduce":function (p) { return p.id; }},model:{value:(_vm.account.city),callback:function ($$v) {_vm.$set(_vm.account, "city", $$v)},expression:"account.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('BCol',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Договор :"}},[_c('v-select',{attrs:{"label":"title","options":_vm.GET_CONTRACTS_LIST,"disabled":_vm.isContract,"reduce":function (p) { return p.id; }},model:{value:(_vm.account.contract),callback:function ($$v) {_vm.$set(_vm.account, "contract", $$v)},expression:"account.contract"}})],1)],1),_c('BCol',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('finance.summ') + ' :'}},[_c('validation-provider',{attrs:{"name":_vm.$t('finance.summ'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"options":_vm.moneyOptions,"placeholder":_vm.$t('finance.summ'),"tabindex":"5"},model:{value:(_vm.account.amount),callback:function ($$v) {_vm.$set(_vm.account, "amount", $$v)},expression:"account.amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{attrs:{"label":_vm.$t('task_manager.placeholder') + ' :'}},[_c('b-form-textarea',{attrs:{"id":"textarea-description","placeholder":_vm.$t('task_manager.placeholder_description')},model:{value:(_vm.account.description),callback:function ($$v) {_vm.$set(_vm.account, "description", $$v)},expression:"account.description"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('client.file') + ' :'}},[_c('validation-provider',{attrs:{"name":_vm.$t('client.file'),"rules":this.editItem.id ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"file","browse-text":_vm.$t('administration.upload'),"placeholder":_vm.$t('general.choose_file_or_drop'),"drop-placeholder":_vm.$t('general.choose_file_or_drop')},model:{value:(_vm.account.file),callback:function ($$v) {_vm.$set(_vm.account, "file", $$v)},expression:"account.file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('AddContractorModal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }