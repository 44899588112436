<template>
  <div>
    <b-table
      :busy="busy"
      :items="items"
      responsive
      :fields="fields"
      select-mode="single"
      selectable
      hover
      @row-selected="onRowSelected"
      :empty-text="$t('administration.no_records')"
      show-empty
      class="mb-0"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" variant="primary"></b-spinner>
        </div>
      </template>
      <template #empty>
        <h4 class="text-center">{{ $t('administration.no_records') }}</h4>
      </template>
      <template #head()="data">
        <span>{{ $t(data.label) }}</span>
      </template>

      <!-- Task ID -->
      <template #cell(task_group)="data">
        <div class="d-flex align-items-center">
          <b-badge
            v-if="filterDataLang(data.item, 'task_type')"
            class="mr-1"
            variant="primary"
            >{{ filterDataLang(data.item, 'task_type') }}
          </b-badge>
        </div>
      </template>

      <!-- Task Title -->
      <template #cell(description)="data">
        <div class="d-flex align-items-center">
          <b-link class="mr-1"> #{{ data.item.id }}</b-link>
          {{ filterDataLang(data.item.doc_type) }}
        </div>
      </template>
      <!-- Task Progress -->
      <template #cell(created_date)="data">
        <h6>{{ getHours(data.item.created_date) }}</h6>
        <div>{{ getDate(data.item.created_date) }}</div>
      </template>

      <template #cell(amount)="data">
        {{ $_moneyFormatter(data.item.amount) }}
      </template>

      <template #cell(is_act_doc)="data">
        <b-avatar v-if="data.item.is_act_doc" variant="light-success" size="35">
          <feather-icon icon="CheckCircleIcon" size="21" />
        </b-avatar>
        <b-avatar v-else variant="light-danger" size="35">
          <feather-icon icon="XCircleIcon" size="21" />
        </b-avatar>
      </template>

      <!-- Task Progress -->
      <template #cell(current_step)="data">
        <b-badge
          :variant="
            data.item.current_step ? data.item.current_step.color : 'secondary'
          "
          v-if="data.item.current_step.title"
        >
          <!-- {{ filterDataLang(data.item.current_step, 'level') }} -->
          {{ data.item.current_step.title[$i18n.locale] }}
        </b-badge>
      </template>

      <!-- Task Progress -->
      <template #cell(is_finished)="data">
        <label>{{ filterDataLang(data.item.current_step) }}</label>
        <b-progress
          :value="data.item.current_step.progress"
          max="100"
          :variant="data.item.current_step.color"
          :class="`progress-bar-${data.item.current_step.color}`"
        />
      </template>

      <!-- TASK EXECUTORS -->
      <template #cell(executers)="data">
        <b-avatar-group
          size="32px"
          v-for="executer in data.item.performers"
          :key="executer.id"
          class="mb-50"
        >
          <div class="d-flex align-items-center">
            <!-- <b-avatar
              :key="data.item.performers.id"
              v-if="data.item.performers"
              :src="data.item.performers.photo"
              v-b-tooltip.hover
              class="pull-up mr-1"
              :title="data.item.performers.phone || data.item.performers.title"
            /> -->
            <b-avatar
              v-if="executer"
              :src="executer.photo"
              v-b-tooltip.hover
              class="pull-up mr-1"
              :title="executer.phone || executer.title"
            />
            {{ executer.title }}
          </div>
        </b-avatar-group>
      </template>

      <!-- TASK observer -->
      <template #cell(observer)="data">
        <b-avatar-group size="32px">
          <div class="d-flex align-items-center">
            <b-avatar
              v-if="data.item.participants"
              v-b-tooltip.hover
              class="pull-up mr-1"
              :title="
                data.item.participants.phone || data.item.participants.title
              "
              :src="data.item.participants.photo"
            />
            {{ data.item.participants.title }}
          </div>
        </b-avatar-group>
      </template>

      <!-- Task Author -->
      <template #cell(author)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            v-b-tooltip.hover
            :title="data.item.author.full_name"
            class="pull-up mr-1"
            :src="data.item.author.photo"
          />
        </div>
      </template>

      <template #cell(actions)="data">
        <div
          class="d-flex justify-content-start align-items-center "
          v-if="data.item.current_step.is_editable"
        >
          <b-button
            class="mr-1"
            size="sm"
            variant="warning"
            @click="$emit('handleEditTask', data.item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            size="sm"
            variant="danger"
            @click="$emit('handleDeleteItem', data.item.id)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </div>
      </template>

      <template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">
              {{ key }}: {{ value }}
            </li>
          </ul>
        </b-card>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BTable,
  BBadge,
  BAvatar,
  BAvatarGroup,
  BLink,
  BButton,
  BProgress,
  VBTooltip,
  BSpinner,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'

import moment from 'moment'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    busy: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    BTable,
    BBadge,
    BSpinner,
    BButton,
    VBTooltip,
    BAvatar,
    BAvatarGroup,
    BLink,
    BProgress,
  },

  data() {
    return {
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      fields: [
        // {
        //   key: 'id',
        //   label: '№',
        //   thClass: 'align-middle',
        // },
        {
          key: 'created_date',
          label: 'task_manager.created_date',
          thClass: 'align-middle',
        },
        {
          key: 'contractor.title',
          label: 'task_manager.client_name',
          thClass: 'align-middle',
        },
        {
          key: 'bill_type.title',
          label: 'manual.doc_type',
          thClass: 'align-middle',
        },
        {
          key: 'current_step',
          label: 'status',
          thClass: 'align-middle',
        },
        {
          key: 'payer.title',
          label: 'task_manager.payer',
          thClass: 'align-middle',
        },
        {
          key: 'contractor.inn',
          label: 'ИНН',
          thClass: 'align-middle',
        },
        {
          key: 'is_act_doc',
          label: 'Акт',
          thClass: 'align-middle',
        },
        {
          key: 'city.title',
          label: 'task_manager.city',
          thClass: 'align-middle',
        },

        {
          key: 'amount',
          label: 'finance.summ',
          thClass: 'align-middle',
        },

        {
          key: 'author',
          label: 'general.author',
          thClass: 'align-middle',
        },
        {
          key: 'actions',
          label: 'finance.actions',
          thClass: 'align-middle',
        },
      ],
    }
  },

  methods: {
    // ...mapActions('taskManager', ['FETCH_ACCOUNTS']),



    getDate(date) {
      if (date) return moment(date).format('DD.MM.YYYY')
    },
    getHours(date) {
      if (date) return moment(date).format('HH:mm')
    },

    filterDataLang(v, key = 'title') {
      if (typeof v[key] === 'string') return v[key]
      else {
        let value = v[key] || {} // убрал проверку на null или undefined
        let locale = this.$i18n.locale
        let def = {
          title: {
            ru: '',
            uz: '',
          },
          level: {
            ru: 'Низкий',
            uz: 'Низкий',
          },
        }
        return value[locale] ? value[locale] : value.ru || def[key][locale]
      }
    },
    onRowSelected(data) {
      this.$emit('onRowSelected', data)
      // this.$router.push({ name: 'task-detail', params: { id: data[0].id } })
    },
  },
  computed: {
    ...mapGetters('taskManager', ['GET_ACCOUNTS']),
  },
}
</script>
