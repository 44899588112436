<template>
  <b-row>
    <b-col
      lg="3"
      sm="6"
      v-for="(s, index) in GET_ACCOUNT_STATISTICS"
      :key="index"
    >
      <statistic-card-horizontal
        :icon="s.icon"
        :color="s.color"
        :statistic="s.amount"
        :statistic-title="s.title"
        :index="index"
      />
    </b-col>

    <!-- <div>
      {{GET_ACCOUNT_STATISTICS}}
    </div> -->
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    StatisticCardHorizontal,
  },

  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      StatisticListData: {
        data: [
          {
            icon: 'Icon',
            key: 'client_total_amount',
            name: 'Общее количество клиентов',
          },
          {
            icon: 'Icon',
            key: 'total_task_amount',
            name: 'Общее количество задач',
          },
          {
            icon: 'Icon',
            key: 'total_finish_task_amount',
            name: 'Завершенные задачи',
          },
          {
            icon: 'Icon',
            key: 'total_unfinish_task_amount',
            name: 'Не завершённые задачи',
          },
        ],
      },
    }
  },
  methods: {
    ...mapActions('taskManager', ['FETCH_ACCOUNT_STATISTICS']),
  },
  computed: {
    ...mapGetters('taskManager', ['GET_ACCOUNT_STATISTICS']),
  },
  mounted() {
    this.FETCH_ACCOUNT_STATISTICS()
  },
}
</script>

<style></style>
