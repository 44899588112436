<template>
  <div>
    <TaskStatistics :params="filter" />

    <b-row>
      <b-col md="3" class="">
        <b-form-group
          :label="$t('task_manager.created_date') + ' ' + $t('general.from_to')"
        >
          <VueDatePicker
            id="passport_expire_date"
            :placeholder="$t('administration.select_date')"
            :other-props="{ 'is-range': true }"
            v-model="created_date"
            @input="changeFilter('created_date', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col md="3" class="">
        <b-form-group :label="$t('task_manager.author') + ':'">
          <v-select
            :placeholder="$t('administration.select_author')"
            v-model="filter.author"
            id="contacts-author"
            :options="GET_USERS_LIST"
            @input="changeFilter('author', $event)"
            label="full_name"
            :reduce="p => p.id"
          />
        </b-form-group>
      </b-col>

      <b-col md="3" class="">
        <b-form-group label="Плательщик : ">
          <v-select
            label="title"
            placeholder="Выберите плательщик"
            v-model="filter.payer"
            :options="GET_PAYERS_LIST"
            @input="changeFilter('payer', $event)"
            :reduce="p => p.id"
          />
        </b-form-group>
      </b-col>

      <b-col md="3" class="">
        <b-form-group label="Контрагенты :">
          <v-select
            label="title"
            placeholder="Выберите контрагенты"
            v-model="filter.contractor"
            :options="GET_CONTRACTORS_LIST"
            @input="changeFilter('contractor', $event)"
            :reduce="p => p.id"
          />
        </b-form-group>
      </b-col>

      <b-col md="3" class="">
        <b-form-group :label="$t('status') + ':'">
          <v-select
            :placeholder="$t('task_manager.select_status')"
            v-model="filter.current_step"
            @input="changeFilter('current_step', $event)"
            :options="GET_STEPS"
            :reduce="item => item.id"
            label="title"
            :get-option-label="item => item.title[$i18n.locale]"
            id="task-status"
          />
        </b-form-group>
      </b-col>

      <b-col md="3" class="">
        <b-form-group :label="$t('manual.doc_type') + ' :'">
          <v-select
            label="title"
            id="document_type"
            placeholder="Выберите тип счёта"
            v-model="filter.bill_type"
            :options="GET_BILL_TYPE_LIST"
            @input="changeFilter('bill_type', $event)"
            :reduce="p => p.id"
          />
        </b-form-group>
      </b-col>

      <b-col md="3" class="">
        <b-form-group label="ИНН :">
          <v-select
            label="inn"
            placeholder="ИНН"
            v-model="filter.contractor"
            :options="GET_CONTRACTORS_LIST"
            :reduce="p => p.id"
            @input="changeFilter('contractor', $event)"
          />
        </b-form-group>
      </b-col>

      <b-col md="3" class="">
        <b-form-group label="Акт :">
          <v-select
            label="title"
            placeholder="Акт"
            v-model="filter.is_act_doc"
            :options="actOptions"
            :reduce="p => p.value"
            @input="changeFilter('is_act_doc', $event)"
          />
        </b-form-group>
      </b-col>

      <b-col md="12" class="my-1 d-flex justify-content-end align-items-center">
        <!-- v-if="is_show_btn" -->
        <b-button variant="success" @click="downloadExcel" class="mr-1">
          <feather-icon icon="DownloadIcon" />
          Скачать
        </b-button>
        <b-button variant="primary" @click="handeAddTask">
          <feather-icon icon="PlusIcon" />
          {{ $t('general.add') }}
        </b-button>
      </b-col>
    </b-row>

    <b-card no-body class="pt-2">
      <TasksTable
        :busy="busy"
        :items="GET_ACCOUNTS.results"
        @onRowSelected="onRowSelected"
        @handleEditTask="handleEditTask"
        @handleDeleteItem="deleteItem"
      />
    </b-card>

    <!-- :current_page="filter.page" -->
    <b-card>
      <!-- <TablePagination
        :count="GET_ACCOUNTS.count"
        @changePaginationValue="changePaginationValue"
      /> -->

      <b-row>
        <b-col md="1">
          <div class="d-flex justify-content-between align-items-center">
            <span class="text-nowrap mr-1">{{
              $t('pagination.show_from')
            }}</span>
            <!-- {{ filter.page_size }} -->
            <b-form-select
              v-model="filter.page_size"
              :options="['10', '15', '25']"
              class="w-auto"
              @change="changeFilter('page_size', $event)"
            />
            <span class="text-nowrap ml-1"> {{ $t('pagination.lines') }} </span>
          </div>
        </b-col>
        <b-col md="5" class="ml-auto">
          <!-- {{ filter.page }} -->
          <b-pagination
            v-model="filter.page"
            :per-page="filter.page_size"
            :total-rows="GET_ACCOUNTS.count"
            align="right"
            aria-controls="my-table"
            first-number
            last-number
            @input="changeFilter('page', $event)"
          >
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>

    <!-- <BCard>
      <Pag />
    </BCard> -->

    <AddTaskModal @refresh="refresh" :editItem="editItem" />
  </div>
</template>

<script>
import TaskStatistics from '@/components/TaskStatistics.vue'
import TablePagination from '@/components/TablePagination.vue'
import FilterCard from './components/FilterCard.vue'
import TasksTable from './components/TasksTable.vue'
import AddTaskModal from './components/AddTaskModal.vue'

import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ru } from 'vuejs-datepicker/dist/locale'
import useJwt from '@/auth/jwt/useJwt'
import { mapActions, mapGetters } from 'vuex'
import VueDatePicker from '@/components/VueDatePicker.vue'
import { getUserData } from '@/auth/utils'
// import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from 'moment'

export default {
  components: {
    BCard,
    FilterCard,
    TaskStatistics,
    TablePagination,

    TasksTable,
    AddTaskModal,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BCard,
    vSelect,
    DatePicker,
    VueDatePicker,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BPagination,
  },
  data() {
    return {
      ru,
      date: '',
      busy: false,
      actOptions: [
        {
          value: true,
          title: 'Есть',
        },
        {
          value: false,
          title: 'Нет',
        },
      ],
      filter: {
        from_date: null,
        to_date: null,
        current_step: null,
        author: null,
        page: 1,
        page_size: 10,
        bill_type: null,
        payer: null,
        contractor: null,
        is_act_doc: null,
      },

      created_date: {
        start: null,
        end: null,
      },

      editItem: {},
    }
  },

  computed: {
    ...mapGetters('users', ['GET_USERS_LIST']),
    ...mapGetters('taskManager', ['GET_STEPS', 'GET_ACCOUNTS']),
    ...mapGetters('knowledgeBase', [
      'GET_BILL_TYPE_LIST',
      'GET_PAYERS_LIST',
      'GET_CONTRACTORS_LIST',
    ]),

    is_show_btn() {
      if (useJwt.getUserData().permission.includes('accountants')) return false
      else return true
    },
  },

  mounted() {
    this.FETCH_CONTRACTORS_LIST()
    this.FETCH_PAYERS_LIST({ user_id: getUserData().id })
    this.FETCH_USERS_LIST()
    this.FETCH_STEPS()
    this.FETCH_BILL_TYPE_LIST()

    // if (this.$_getFilter()) {
    //   this.filter = { ...this.$_getFilter() }
    // }

    this.refresh()
  },

  methods: {
    ...mapActions('taskManager', [
      'FETCH_ACCOUNTS',
      'FETCH_ACCOUNT_STATISTICS',
      'DELETE_ACCOUNT',
      'FETCH_STEPS',
      'FETCH_DOWNLOAD_EXCEL',
    ]),

    ...mapActions('users', ['FETCH_USERS_LIST']),

    ...mapActions('knowledgeBase', [
      'FETCH_BILL_TYPE_LIST',
      'FETCH_PAYERS_LIST',
      'FETCH_CONTRACTORS_LIST',
    ]),

    customFormatter(date) {
      if (date) {
        return moment(date).format('YYYY-MM-DD')
      } else {
        return null
      }
    },

    reset() {
      this.created_date = null
    },
    clear(e) {
      if (e.code === 'Backspace') {
        this.created_date = { start: null, end: null }
        this.refresh()
      }
    },


    downloadExcel() {
      const { created_date, ...rest } = this.filter

      const data = {
        from_date: created_date?.start || '',
        to_date: created_date?.end || '',
        ...rest,
      }
      this.FETCH_DOWNLOAD_EXCEL(data).then(b => {
        var a = document.createElement('a')
        a.href = URL.createObjectURL(b)
        a.setAttribute('download', 'url')
        a.click()
      })
    },

    exportExcel() {
      this.FETCH_DOWNLOAD_EXCEL(this.filter).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'file.xlsx')
        document.body.appendChild(link)
        link.click()
      })
    },

    handleEditTask(item) {
      this.editItem = item

      this.$nextTick(() => {
        this.$bvModal.show('add-task-modal')
      })
    },

    deleteItem(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          if (this.GET_ACCOUNTS.results.length === 1) {
            if (this.filter.page > 1) --this.filter.page
          }
          this.DELETE_ACCOUNT(id)
            .then(() => {
              this.refresh()
              this.$_successToast(this.$t('general.success'))
              this.filter.page = 1
            })
            .catch(() => {
              this.$_errorToast(this.$t('error'))
            })
        }
      })
    },

    handeAddTask() {
      this.editItem = {}

      this.$nextTick(() => {
        this.$bvModal.show('add-task-modal')
      })
    },

    onRowSelected(data) {
      this.$router.push({ name: 'task-detail', params: { id: data[0].id } })
    },

    async refresh() {
      const sessionFilter = this.$_getFilter()

      console.log('sessionFilter', sessionFilter)

      if (sessionFilter) {
        this.filter = { ...sessionFilter }

        console.log('this.filter', this.filter)

        if (this.created_date) {
          this.created_date.start = this.filter?.from_date
          this.created_date.end = this.filter?.to_date
        }
      }

      console.log('this.filter', this.filter)

      // delete this.filter.contractor

      try {
        this.busy = true
        await this.FETCH_ACCOUNTS(this.filter)
      } catch (error) {
        this.$_errorToast(this.$t('error'))
      }
      this.busy = false
    },

    changeFilter(type, value) {
      this.filter.page = 1

      if (type === 'created_date') {
        this.filter.from_date = value?.start || ''
        this.filter.to_date = value?.end || ''
      } else this.filter[type] = value

      this.$_saveFilter(this.filter)

      this.refresh()

      this.FETCH_ACCOUNT_STATISTICS(this.filter)
    },
  },
}
</script>


<style scoped>
.vs--single {
  background: white;
  border-radius: 5px;
}
.vs--single::placeholder {
  color: blue;
}
</style>

