<template>
  <div>
    <b-row>
      <b-col md="3" class="">
        <b-form-group
          :label="
            $t('task_manager.created_date') + ' ' + $t('general.from_to') + ':'
          "
        >
          <VueDatePicker
            id="passport_expire_date"
            :placeholder="$t('administration.select_date')"
            :other-props="{ 'is-range': true }"
            v-model="filterData.created_date"
            @input="$emit('changeFilter', 'created_date', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col md="3" class="">
        <b-form-group :label="$t('task_manager.author') + ':'">
          <v-select
            :placeholder="$t('administration.select_author')"
            v-model="filterData.author"
            id="contacts-author"
            :options="GET_USERS_LIST"
            @input="$emit('changeFilter', 'author', $event)"
            label="full_name"
            :reduce="p => p.id"
          />
        </b-form-group>
      </b-col>

      <b-col md="3" class="">
        <b-form-group label="Плательщик : ">
          <v-select
            label="title"
            placeholder="Выберите плательщик"
            v-model="filterData.payer"
            :options="GET_PAYERS_LIST"
            @input="$emit('changeFilter', 'payer', $event)"
            :reduce="p => p.id"
          />
        </b-form-group>
      </b-col>

      <b-col md="3" class="">
        <b-form-group label="Контрагенты :">
          <v-select
            label="title"
            placeholder="Выберите контрагенты"
            v-model="filterData.сontractor"
            :options="GET_CONTRACTORS_LIST"
            @change="$emit('changeFilter', 'contractor', $event)"
            :reduce="p => p.id"
          />
        </b-form-group>
      </b-col>

      <b-col md="3" class="">
        <b-form-group :label="$t('status') + ':'">
          <v-select
            :placeholder="$t('task_manager.select_status')"
            v-model="filterData.current_step"
            @input="$emit('changeFilter', 'current_step', $event)"
            :options="GET_STEPS"
            :reduce="item => item.id"
            label="title"
            :get-option-label="item => item.title[$i18n.locale]"
            id="task-status"
          />
        </b-form-group>
      </b-col>

      <b-col md="3" class="">
        <b-form-group :label="$t('manual.doc_type') + ' :'">
          <v-select
            label="title"
            id="document_type"
            placeholder="Выберите тип счёта"
            v-model="filterData.bill_type"
            :options="GET_BILL_TYPE_LIST"
            @input="$emit('changeFilter', 'bill_type', $event)"
            :reduce="p => p.id"
          />
        </b-form-group>
      </b-col>

      <b-col md="3" class="">
        <b-form-group label="ИНН :">
          <v-select
            label="inn"
            placeholder="ИНН"
            v-model="filterData.contractor"
            :options="GET_CONTRACTORS_LIST"
            :reduce="p => p.id"
            @change="$emit('changeFilter', 'contractor', $event)"
          />
        </b-form-group>
      </b-col>

      <b-col md="3" class="">
        <b-form-group label="Акт :">
          <v-select
            label="title"
            placeholder="Акт"
            v-model="filterData.is_act_doc"
            :options="actOptions"
            :reduce="p => p.value"
            @input="$emit('changeFilter', 'is_act_doc', $event)"
          />
        </b-form-group>
      </b-col>

      <b-col md="12" class="my-1 d-flex justify-content-end align-items-center">
        <!-- v-if="is_show_btn" -->
        <b-button variant="success" @click="downloadExcel" class="mr-1">
          <feather-icon icon="DownloadIcon" />
          Скачать
        </b-button>
        <b-button variant="primary" @click="$emit('handeAddTask')">
          <feather-icon icon="PlusIcon" />
          {{ $t('general.add') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BFormGroup, BButton } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import { ru } from 'vuejs-datepicker/dist/locale'
import useJwt from '@/auth/jwt/useJwt'
import { mapActions, mapGetters } from 'vuex'
import VueDatePicker from '@/components/VueDatePicker.vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    vSelect,
    VueDatePicker,
    Datepicker,
  },

  data() {
    return {
      ru,
      actOptions: [
        {
          value: true,
          title: 'Есть',
        },
        {
          value: false,
          title: 'Нет',
        },
      ],
      filterData: {
        current_step: null,
        author: null,
        created_date: {
          start: null,
          end: null,
        },
        contractor: null,
        bill_type: null,
        payer: null,
        is_act_doc: null,
      },
    }
  },

  computed: {
    ...mapGetters('users', ['GET_USERS_LIST']),
    ...mapGetters('taskManager', ['GET_STEPS']),
    ...mapGetters('knowledgeBase', [
      'GET_BILL_TYPE_LIST',
      'GET_PAYERS_LIST',
      'GET_CONTRACTORS_LIST',
    ]),

    is_show_btn() {
      if (useJwt.getUserData().permission.includes('accountants')) return false
      else return true
    },
  },

  methods: {
    ...mapActions('users', ['FETCH_USERS_LIST']),
    ...mapActions('taskManager', ['FETCH_STEPS', 'FETCH_DOWNLOAD_EXCEL']),
    ...mapActions('knowledgeBase', [
      'FETCH_BILL_TYPE_LIST',
      'FETCH_PAYERS_LIST',
      'FETCH_CONTRACTORS_LIST',
    ]),

    downloadExcel() {
      // this.FETCH_DOWNLOAD_EXCEL(this.filterData)
      const { created_date, ...rest } = this.filterData
      const data = {
        from_date: created_date?.start || '',
        to_date: created_date?.end || '',
        ...rest,
      }
      this.FETCH_DOWNLOAD_EXCEL(data).then(b => {
        var a = document.createElement('a')
        a.href = URL.createObjectURL(b)
        a.setAttribute('download', 'url')
        a.click()
      })
    },

    exportExcel() {
      this.FETCH_DOWNLOAD_EXCEL(this.filterData).then(res => {
        // console.log('responseeeeeee', res)

        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'file.xlsx')
        document.body.appendChild(link)
        link.click()
      })
    },
  },
  mounted() {
    this.FETCH_CONTRACTORS_LIST()
    this.FETCH_USERS_LIST()
    this.FETCH_PAYERS_LIST({ user_id: getUserData().id })
    this.FETCH_STEPS()
    this.FETCH_BILL_TYPE_LIST()
  },
}
</script>

<style scoped>
.vs--single {
  background: white;
  border-radius: 5px;
}
.vs--single::placeholder {
  color: blue;
}
</style>
