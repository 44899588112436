small<template>
  <b-modal
    id="add-task-modal"
    size="lg"
    ok-variant="success"
    centered
    no-close-on-backdrop
    cancel-variant="danger"
    :title="$t('task_manager.add_task')"
    :ok-title="$t('save')"
    :cancel-title="$t('client.cancel')"
    @show="onOpen"
    @ok="onSubmit"
    @cancel="$bvModal.hide('add-task-modal')"
  >
    <validation-observer ref="accountValidation">
      <BRow>
        <BCol md="12">
          <b-form-group label="Контрагенты:">
            <validation-provider
              #default="{ errors }"
              name="Контрагенты"
              rules="required"
            >
              <div class="d-flex justify-content-between align-items-center">
                <v-select
                  label="title"
                  v-model="account.contractor"
                  :options="GET_CONTRACTORS_LIST"
                  :reduce="p => p.id"
                  @input="fetchSettlementAccount"
                  class="flex-grow-1 mr-1"
                />
                <b-button
                  variant="success"
                  @click="$bvModal.show('add-counter-parties-modal')"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </BCol>
        <BCol md="6">
          <b-form-group label="ИНН :">
            <validation-provider
              #default="{ errors }"
              name="инн"
              rules="required|integer"
            >
              <v-select
                label="inn"
                v-model="account.contractor"
                :options="GET_CONTRACTORS_LIST"
                :reduce="p => p.id"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </BCol>
        <BCol md="6">
          <!-- {{ account.settlement_account }} -->

          <b-form-group label="Расчетный счет контрагента:">
            <!-- <validation-provider
              #default="{ errors }"
              name="Расчетный счет контрагента"
              rules="required"
            > -->
            <!-- :placeholder="$t('details.select_document_type')" -->
            <v-select
              label="title"
              id="document_type"
              v-model="account.settlement_account"
              :options="GET_SETTLEMENT_ACCOUNT_BY_CONTRACTOR"
              :reduce="p => p.id"
              :disabled="!account.contractor"
            />
            <!-- :get-option-label="v => v.title[$i18n.locale] || ''" -->
            <!-- <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider> -->
          </b-form-group></BCol
        >

        <BCol md="6">
          <b-form-group label="Тип счета:">
            <validation-provider
              #default="{ errors }"
              :name="$t('details.select_document_type')"
              rules="required"
            >
              <v-select
                label="title"
                id="document_type"
                :placeholder="$t('details.select_document_type')"
                v-model="account.bill_type"
                :options="GET_BILL_TYPE_LIST"
                :reduce="p => p.id"
              />
              <!-- :get-option-label="v => v.title[$i18n.locale] || ''" -->
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group></BCol
        >
        <BCol md="6">
          <b-form-group label="Плательщик:">
            <validation-provider
              #default="{ errors }"
              name="Плательщик"
              rules="required"
            >
              <v-select
                label="title"
                v-model="account.payer"
                :options="GET_PAYERS_LIST"
                :reduce="p => p.id"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group></BCol
        >
        <BCol md="6">
          <b-form-group label="Город :">
            <validation-provider
              #default="{ errors }"
              name="Город "
              rules="required"
            >
              <v-select
                label="title"
                v-model="account.city"
                :options="GET_CITIES_LIST"
                :reduce="p => p.id"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group></BCol
        >
        <BCol md="6">
          <b-form-group label="Договор :">
            <v-select
              label="title"
              v-model="account.contract"
              :options="GET_CONTRACTS_LIST"
              :disabled="isContract"
              :reduce="p => p.id"
            /> </b-form-group
        ></BCol>
        <BCol md="12">
          <b-form-group :label="$t('finance.summ') + ' :'">
            <validation-provider
              #default="{ errors }"
              :name="$t('finance.summ')"
              rules="required"
            >
              <cleave
                v-model="account.amount"
                :options="moneyOptions"
                class="form-control"
                :placeholder="$t('finance.summ')"
                tabindex="5"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group></BCol
        >
      </BRow>

      <b-form-group :label="$t('task_manager.placeholder') + ' :'">
        <b-form-textarea
          id="textarea-description"
          v-model="account.description"
          :placeholder="$t('task_manager.placeholder_description')"
        />
      </b-form-group>

      <b-form-group :label="$t('client.file') + ' :'">
        <validation-provider
          #default="{ errors }"
          :name="$t('client.file')"
          :rules="this.editItem.id ? '' : 'required'"
        >
          <b-form-file
            id="file"
            v-model="account.file"
            :browse-text="$t('administration.upload')"
            :placeholder="$t('general.choose_file_or_drop')"
            :drop-placeholder="$t('general.choose_file_or_drop')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </validation-observer>
    <AddContractorModal />
  </b-modal>
</template>

<script>
import {
  BTabs,
  BTab,
  BModal,
  BFormInput,
  BFormGroup,
  BFormFile,
  BProgress,
  BFormRadio,
  BFormRadioGroup,
  BFormTextarea,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import { required, integer, length } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import useJwt from '@/auth/jwt/useJwt'
import AddContractorModal from './AddContractorModal.vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BModal,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BTabs,
    BProgress,
    BButton,
    BTab,
    BFormRadio,
    BFormRadioGroup,
    BFormTextarea,
    vSelect,
    Cleave,
    BRow,
    BCol,
    AddContractorModal,
  },

  props: {
    editItem: {
      type: Object,
    },
  },
  data() {
    return {
      integer,
      length,
      inn: '',
      account: {
        bill_type: null,
        amount: null,
        file: null,
        description: null,
        contractor: '',
        payer: '',
        city: '',
        contract: null,
        act_doc: null,
        settlement_account: null,
      },
      moneyOptions: {
        numeral: true,
        // prefix: ' UZS',
        rawValueTrimPrefix: true,
        numeralPositiveOnly: true,
        tailPrefix: true,
        noImmediatePrefix: true,
        numeralThousandsGroupStyle: 'thousand',
      },
    }
  },
  mounted() {
    // this.FETCH_BILL_TYPE_LIST()
    this.FETCH_CITIES_LIST()
    this.FETCH_PAYERS_LIST({
      user_id: getUserData().id,
    })
    this.FETCH_CONTRACTORS_LIST()
  },
  computed: {
    ...mapGetters('knowledgeBase', [
      'GET_BILL_TYPE_LIST',
      'GET_PAYERS_LIST',
      'GET_CITIES_LIST',
      'GET_CONTRACTORS_LIST',
      'GET_SETTLEMENT_ACCOUNT_BY_CONTRACTOR',
    ]),
    ...mapGetters('taskManager', ['GET_CONTRACTS_LIST']),

    isContract() {
      if (this.account.city && this.account.contractor && this.account.payer) {
        return false
      } else {
        return true
      }
    },
  },
  methods: {
    ...mapActions('knowledgeBase', [
      'FETCH_BILL_TYPE_LIST',
      'FETCH_CITIES_LIST',
      'FETCH_PAYERS_LIST',
      'FETCH_CONTRACTOR_WITH_INN',
      'FETCH_CONTRACTORS_LIST',
      'FETCH_SETTLEMENT_ACCOUNT_BY_CONTRACTOR',
    ]),
    ...mapActions('taskManager', [
      'CREATE_ACCOUNT',
      'UPDATE_ACCOUNT',
      'FETCH_CONTRACTS_LIST',
    ]),

    async onSubmit(evt) {
      evt.preventDefault()

      const res = await this.$refs.accountValidation.validate()
      if (!res) {
        return
      }
      const formData = this.$_objectToFormData(this.account)

      // formData.append('act_doc', null)

      if (this.editItem.id) {
        formData.append('author', this.editItem.author.id)
      } else {
        formData.append('author', useJwt.getUserData()?.id)
      }

      try {
        if (this.editItem.id) {
          await this.UPDATE_ACCOUNT({
            data: formData,
            id: this.editItem.id,
          })
        } else {
          await this.CREATE_ACCOUNT(formData)
        }

        this.$emit('refresh')

        this.$nextTick(() => {
          this.$_successToast(this.$t('client.successfully_added'))
          this.$bvModal.hide('add-task-modal')
        })
      } catch (error) {
        console.log(error)

        let err = this.$t('general.error')
        if (error?.response.data) {
          err = error?.response.data[0]
        }
        // console.log('error.res', error.response.data)

        this.$_errorToast(err)
      }
    },

    fetchSettlementAccount(contractor_id) {
      if (contractor_id) {
        this.FETCH_SETTLEMENT_ACCOUNT_BY_CONTRACTOR({
          contractor_id: contractor_id,
        })
      } else {
        this.account.settlement_account = ''
      }
    },

    fetchContractsList() {
      if (this.account.city && this.account.contractor && this.account.payer) {
        this.FETCH_CONTRACTS_LIST({
          city: this.account.city,
          contractor: this.account.contractor,
          payer: this.account.payer,
        })
      } else {
        this.account.contract = null
      }
    },

    async handleInputInn(event) {
      this.account.contractor = ''

      if (this.inn.length == 14) {
        const response = await this.FETCH_CONTRACTOR_WITH_INN({
          inn: event,
        })

        this.account.contractor = response?.id
      }
    },

    handleInputContractor(id) {
      this.inn = ''
      // console.log('GET_CONTRACTORS_LIST', this.FETCH_CONTRACTOR_WITH_INN())
      const client = this.GET_CONTRACTORS_LIST.find(p => p.id == id)

      this.inn = client.inn
    },

    filterDataLang(v = {}, key = 'title') {
      let value = v[key] || {}
      let locale = this.$i18n.locale
      let def = {
        title: {
          ru: '',
          uz: '',
        },
        level: {
          ru: 'Низкий',
          uz: 'Низкий',
        },
      }
      return value[locale] ? value[locale] : value.ru || def[key][locale]
    },

    async onOpen() {
      this.account.file = null

      if (this.editItem?.id) {
        this.account.bill_type = this.editItem.bill_type.id
        this.account.amount = this.editItem.amount
        this.account.contractor = this.editItem.contractor.id

        await this.fetchSettlementAccount(this.account.contractor)
        this.account.description = this.editItem.description
        this.account.payer = this.editItem.payer.id
        this.account.city = this.editItem.city.id
        this.inn = this.account.contractor.inn

        if (this.editItem.contract.id) {
          this.account.contract = this.editItem.contract.id
        }

        if (this.editItem.settlement_account.id) {
          this.account.settlement_account = this.editItem.settlement_account.id
        }
        // this.account.contract  = this.editItem?.contract?.id

        // if (this.editItem.contract.id) {
        //   this.account.contract = this.editItem.contract.id
        // } else {
        //   this.account.contract = null
        // }
      } else {
        this.inn = ''
        this.account = {
          bill_type: null,
          amount: null,
          file: null,
          contractor: '',
          description: null,
          payer: '',
          city: '',
          contract: null,
          act_doc: null,
          settlement_account: null,
        }
      }
    },
  },

  watch: {
    'account.city': {
      handler() {
        this.fetchContractsList()
      },
    },
    'account.payer': {
      handler() {
        this.fetchContractsList()
      },
    },
    'account.contractor': {
      handler() {
        this.fetchContractsList()
      },
    },
  },
}
</script>
