<template>
  <div>
    <date-picker
      v-model="dateValue"
      :model-config="returnFormat"
      :locale="$i18n.locale"
      :masks="{ input: showFormat }"
      :timezone="timezone"
      :mode="mode"
      v-bind="otherProps"
    >
      <!-- inputValue, -->
      <template v-slot="{ togglePopover }">
        <b-input-group>
          <b-form-input
            :placeholder="placeholder"
            class="bg-white"
            :class="dateValue ? 'round-0' : ''"
            :disabled="disabled"
            :value="inputValue"
            :readonly="readonly"
            @keydown="clear"
            @click="togglePopover()"
          />
          <b-input-group-append v-if="dateValue" is-text>
            <feather-icon icon="XIcon" class="cursor-pointer" @click="reset" />
          </b-input-group-append>
        </b-input-group>
      </template>
    </date-picker>
  </div>
  <!-- for example -->

  <!--
      <vue-date-picker
        v-model="date"
        placeholder="sdfsdf"
        :return-format="{ type: 'string', mask: 'YYYY-MM-DD' }"
        show-format="DD.MM.YYYY"

         :other-props="{'is-range': true}"
         :other-props="{'first-day-of-week': 1}"
      />
  -->
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { BFormInput, BInputGroupAppend, BInputGroup } from 'bootstrap-vue'

export default {
  components: {
    DatePicker,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
  },
  props: {
    // !
    value: {
      type: [String, Object],
      required: false,
      default: '',
    },
    // all Props
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    timezone: {
      type: String,
      required: false,
      default: '',
    },
    locale: {
      type: [String, Object],
      required: false,
      default: 'ru',
    },
    returnFormat: {
      type: Object,
      required: false,
      default: () => ({
        type: 'string',
        mask: 'YYYY-MM-DD',
      }),
    },
    showFormat: {
      type: String,
      required: false,
      default: 'DD.MM.YYYY',
    },
    mode: {
      type: String,
      required: false,
      default: 'date',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: true,
    },
    otherProps: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      dateValue: this.value,
    }
  },

  computed: {
    inputValue() {
      return typeof this.dateValue === 'string'
        ? this.dateValue
        : this.check(this.dateValue)
    },
  },
  watch: {
    dateValue(val) {
      this.$emit('input', val)
    },
  },
  methods: {
    reset() {
      this.dateValue = null
    },
    clear(e) {
      if (e.code === 'Backspace') this.dateValue = null
    },
    check(e) {
      if (e?.start) {
        return `${e.start || ''}-${e.end || ''}`
      }
      return null
    },
  },
}
</script>

<style>
.round-0 {
  border-right: 0;
}
</style>
